<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px; " class="q-my-xl">
      <q-card-section class="row justify-end">
        <q-input
          outlined
          label="Cari Siswa"
          v-model="searchTerm"
          style="max-width:300px"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-card-section horizontal>
        <q-card-section class="column q-pt-none justify-between">
          <div class="q-gutter-md" style="min-width:300px">
            <q-select
              outlined
              v-model="selTingkat"
              label="Tingkat"
              :options="tingkat"
              @input="getListKelas(), (selKelas = null), (siswa = [])"
            />
            <q-select
              outlined
              v-model="selKelas"
              label="Kelas"
              :options="kelas"
              :disable="selTingkat == null"
              @input="getSiswaList()"
            />
            <q-btn
              v-can="[]"
              style="width:300px"
              color="primary"
              :disable="selKelas == null"
              @click="update()"
              label="SIMPAN"
            ></q-btn>
          </div>
          <q-btn
            v-can="[]"
            color="negative"
            label="KOSONGKAN KELAS"
            :disable="selKelas == null"
            @click="guard = true"
          ></q-btn>
        </q-card-section>
        <q-markup-table
          flat
          bordered
          dense
          separator="horizontal"
          class="stickyTable bg-indigo-1 full-width bg-grey-4"
          style="height: calc(100vh - 267px);"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th>no</th>
              <th>nama</th>
              <th>nisn</th>
              <th style="width:200px">
                <q-select
                  dense
                  class="bg-white"
                  outlined
                  filled
                  label="INPUT MASAL"
                  :options="status"
                  v-model="selMasal"
                  @input="statusGenerator"
                ></q-select>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-if="selKelas == null || selTingkat == null">
              <td class="text-center" colspan="5">
                Silahkan Pilih Tingkat dan Kelas Dahulu
              </td>
            </tr>
            <tr v-else-if="siswa.length == 0">
              <td class="text-center" colspan="5">
                Tidak ada siswa di Kelas ini
              </td>
            </tr>
            <tr v-for="(val, i) in filSiswa" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ val.nama }}</td>
              <td>{{ val.nisn }}</td>
              <td>
                <q-select
                  dense
                  class="bg-white"
                  outlined
                  filled
                  :options="status"
                  v-model="val.status"
                  @input="selMasal = '-'"
                ></q-select>
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
    </q-card>
    <q-dialog v-model="guard" persistent>
      <q-card class="bg-negative">
        <q-card-section class="row items-center">
          <q-avatar icon="report_problem" color="negative" text-color="white" />
          <span class="q-ml-sm text-white text-bold">
            Apa Anda yakin akan mengeluarkan seluruh siswa dari kelas?
          </span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="TIDAK" color="white" v-close-popup />
          <q-btn flat label="YA" color="white" @click="empty()" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      guard: false,
      user: {},

      siswa: [],
      kelas: [],
      selKelas: null,
      tingkat: [],
      selTingkat: null,
      searchTerm: "",

      status: [
        "BERJALAN",
        "CUTI",
        "LULUS",
        "NAIK KELAS",
        "DROP OUT",
        "TINGGAL KELAS",
        "PINDAH SEKOLAH",
      ],
      selMasal: "-",
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    },
  },
  async mounted() {
    await this.getUser();
    await this.getListTingkat();
    await this.getListKelas();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    empty() {
      let temp = [];
      for (let item of this.siswa) {
        if (item.status != null) {
          temp.push(item);
        }
      }
      this.$http
        .put(
          `/pengaturan/rombel/empty/${localStorage.getItem("id_tahun_ajaran")}`,
          temp
        )
        .then((resp) => {
          this.$q.notify({
            message: "Seluruh siswa berhasil dikeluarkan!",
            color: "negative",
          });
          this.getSiswaList();
        });
    },
    statusGenerator() {
      this.siswa.forEach((element) => {
        element.status = this.selMasal;
      });
    },
    update() {
      let temp = [];
      for (let item of this.siswa) {
        if (item.status != null) {
          temp.push(item);
        }
      }
      this.$http.put("/pengaturan/rombel/editstatus", temp).then((resp) => {
        this.$q.notify({
          message: "Status siswa berhasil di Input!",
          color: "positive",
        });
        this.getSiswaList();
      });
    },
    async getSiswaList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          `/pengaturan/rombel/getsiswa/${
            this.selKelas.value
          }/${localStorage.getItem("jenjang")}`
        )
        .then((resp) => {
          this.siswa = resp.data;
        });
      this.$q.loading.hide();
    },
    async getListTingkat() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get("/pengaturan/rombel/gettingkat/" + localStorage.getItem("jenjang"))
        .then((resp) => {
          this.tingkat = resp.data;
          this.selTingkat = this.tingkat[this.tingkat.length - 1];
        });
      this.$q.loading.hide();
    },
    async getListKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          `/pengaturan/rombel/getkelas/${this.selTingkat.value}/${
            this.user.id
          }/${localStorage.getItem("jenjang")}/${this.user.is_super_user}`
        )
        .then((resp) => {
          resp.data.unshift({ value: 0, label: "Tanpa Kelas" });
          this.kelas = resp.data;
        });
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
